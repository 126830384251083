import { LocalStorage, LocalStorageKeys } from './LocalStorage';
import { isCart, isNewIn } from './types';

export {
  LocalStorage, LocalStorageKeys, isCart, isNewIn,
};

export const getPriceFormat = (str : any) => parseFloat(str).toFixed(2);

export const isBrowser = typeof window !== 'undefined';

export const isDevice = () => isBrowser && window.innerWidth <= 768;

export const dayInMs = (1000 * 60 * 60 * 24);

export const uniq = (a : any) => [...new Set(a)];

export const getUniqueListBy = (arr: any, key: string) => [...new Map(arr.map((item: any) => [item[key], item])).values()];
