import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Button } from '../shared/Button';

import {
  color,
  font,
  desktopVW,
  minTablet,
  maxTablet,
  mobileVW,
} from '../styles';
import { Socials } from '../shared/Socials';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query getFooterMenu {
      allWpMenu(filter: {slug: {in: ["footer-menu-col1", "footer-menu-col2", "footer-menu-col3"]}}) {
        edges {
          node {
            menuItems {
              nodes {
                id
                parentId
                label
                path
                childItems {
                  nodes {
                    id
                    path
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const colTitles = ['Verhuur', 'Support', 'Informatie'];

  return (
    <StyledFooter>
      <Top>
        <H2>Direct huren bij Mijnsilentdisco.nl</H2>
        <StyledButton type="link" url="#">Verhuur</StyledButton>
      </Top>
      <Bottom>
        <Col>
          <LogoContainer>
            <StyledLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.23 115.44">
              <path d="M156.21 2.87h20.18v107.61h-20.18zM217.57 34.81c0-17.64-14.3-31.95-31.95-31.95h-1.42v20.18h1.42c6.5 0 11.77 5.27 11.77 11.77s-5.27 11.77-11.77 11.77h-1.42v20.18h1.42c17.65 0 31.95-14.3 31.95-31.95M188.16 110.47c17.64 0 31.95-14.3 31.95-31.95 0-6.32-1.84-12.21-5.01-17.17a39.705 39.705 0 01-16.88 11.08 11.69 11.69 0 011.71 6.08c0 6.5-5.27 11.77-11.77 11.77v.01h-3.96v20.18h3.96zM4.53 2.93H24.7v107.58H4.53z" />
              <g><path d="M65.87 34.87c0-17.64-14.3-31.94-31.94-31.94h-1.42V23.1h1.42c6.5 0 11.77 5.27 11.77 11.77s-5.27 11.77-11.77 11.77h-1.42v20.17h1.42c17.64 0 31.94-14.3 31.94-31.94M33.93 74.54h-.21l13.3 35.97h21.39L53.28 69.49a39.48 39.48 0 01-19.35 5.05M78.65 33.12v47.19c0 13.48 8.36 25.01 20.17 29.7V3.42C87 8.11 78.65 19.64 78.65 33.12M142.52 56.05h-35.89v20.17h15.72v4.1c0 6.5-5.27 11.77-11.77 11.77h-3.95v19.93c1.3.16 2.61.24 3.95.24 17.64 0 31.94-14.3 31.94-31.94V56.05zM122.35 33.13v4.73h20.17v-4.73c0-17.64-14.3-31.94-31.94-31.94-1.34 0-2.66.08-3.95.24v19.93h3.95c6.5 0 11.77 5.27 11.77 11.77" /></g>
            </StyledLogo>
          </LogoContainer>
          <Copyright>
            All rights reserved.
            <br />
            &copy; 2021 by
            {' '}
            RGB Disco
            {' '}
            b.v
          </Copyright>
        </Col>
        {/* @ts-ignore */}
        {data.allWpMenu.edges.map(({ node }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={colTitles[index]}>
            <Strong>{colTitles[index]}</Strong>
            <ul>
              {/* @ts-ignore */}
              {node.menuItems.nodes.map((item) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={item.label}>
                  <Link to={item.path}>{item.label}</Link>
                </li>
              ))}
            </ul>
          </Col>
        ))}
        <Social>
          <Socials />
        </Social>
      </Bottom>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  margin: ${mobileVW(80)} 0 ${mobileVW(50)};
  text-align: center;
  
  ${minTablet} {
    margin: ${desktopVW(160)} 0 ${desktopVW(120)};
    text-align: inherit;
  }
`;

const Top = styled.div`
  text-align: center;
  padding: 0 ${mobileVW(30)};

  ${minTablet} {
    padding: 0 ${desktopVW(180)};
  }
`;

const H2 = styled.h2`
  color: ${color.pink};
  margin-bottom: ${mobileVW(30)};
  font-weight: ${font.weightExtraBold};
  margin-bottom: 0;

  ${maxTablet} {
    font-size: ${mobileVW(36)};
    line-height: ${mobileVW(42)};
  }
  
  ${minTablet} {
    font-size: ${font.sizeHeadingLg};
    line-height: ${font.lineHeightHeadingLg};
  }
`;

const StyledButton = styled(Button)`
  background-color: ${color.pink};
  margin-top: ${mobileVW(25)};
  padding: ${mobileVW(13)} ${mobileVW(30)};
  border-radius: ${mobileVW(7)};
  border: none;

  &:hover {
      background: transparent;
      color: ${color.black};
      border: 1px solid #C5C5C5;
    }
    
  border: 1px solid ${color.pink};
  transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

  ${minTablet} {
    margin-top: ${desktopVW(25)};
    padding: ${desktopVW(13)} ${desktopVW(30)};
    border-radius: ${desktopVW(7)};
  }
`;

const Bottom = styled.div`
  display: flex;
  padding: ${mobileVW(60)} ${mobileVW(15)} 0 ${mobileVW(15)};
  ${maxTablet} {
    flex-direction: column;
  }
  ${minTablet} {
    justify-content: space-between;
    padding: ${desktopVW(140)} ${desktopVW(120)} 0 ${desktopVW(120)};
  }
`;

const Col = styled.div`
  ${maxTablet} {
    margin-bottom: ${mobileVW(30)};
  
    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      order: 1;
    }
  }
  ${minTablet} {
    line-height: ${desktopVW(38)};
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;

const Copyright = styled.p`
    line-height: ${mobileVW(38)};
    ${minTablet} {
      line-height: ${desktopVW(38)};
    }
`;

const LogoContainer = styled.div`
  height: ${mobileVW(40)};
  ${minTablet} {
    height: ${desktopVW(50)};
  }
`;

const Strong = styled.strong`
  font-weight: bold;
  height: ${mobileVW(30)};
  line-height: 1;
  display: block;
  ${minTablet} {
    height: ${desktopVW(50)};
  }
`;

const StyledLogo = styled.svg`
  width: ${mobileVW(44)};
  fill: ${color.black};
  ${minTablet} {
    width: ${desktopVW(44)};
  }
`;

const Social = styled.div`
  display: flex;
  gap: ${mobileVW(15)};

  ${maxTablet} {
    order: 1;
    align-self: center;
  }

  ${minTablet} {
    gap: ${desktopVW(15)};
  }
`;

export default Footer;
