import React from 'react';
import styled from 'styled-components';
import {
  headingXl, headingLg, headingMd, headingRg, headingSm, headingXs, headingXxs, introRg, linkSm,
} from '../styles';

export const Typography = ({
  size, variants, children, className,
} : {size?: string, variants?: any, children: any, className?: any}) => {
  // @ts-ignore
  const Font = (style: any) => <StyledTypography className={className} type={variants} styles={style}>{children}</StyledTypography>;

  switch (size) {
    case 'is-xxs':
      return Font(headingXxs);
    case 'is-xs':
      return Font(headingXs);
    case 'is-sm':
      return Font(headingSm);
    case 'is-rg':
      return Font(headingRg);
    case 'is-md':
      return Font(headingMd);
    case 'is-lg':
      return Font(headingLg);
    case 'is-xl':
      return Font(headingXl);
    case 'intro-is-rg':
      return Font(introRg);
    case 'link-sm':
      return Font(linkSm);
    default:
      return <p style={{ whiteSpace: 'nowrap' }}>🚨🚨🚨 HEADINNNGGG NOT FOUND!! 🚨🚨🚨</p>;
  }
};

const StyledTypography = styled.h1.attrs(({ type } : {type: any}) => ({
  as: type,
}))`${({ styles } : {styles: any}) => styles}`;
