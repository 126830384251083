// @ts-ignore
import ShopifyBuy from 'shopify-buy';
import { useCartItems } from './useCartItems';

export function useGetLineItem() {
  const cartItems = useCartItems();

  function getLineItem(variantId: string | number): ShopifyBuy.LineItem | null {
    if (cartItems.length < 1) {
      return null;
    }

    const item = cartItems.find((cartItem: any) =>
      // @ts-ignore
      // eslint-disable-next-line implicit-arrow-linebreak
      cartItem.variant.id === variantId);

    if (item == null) {
      return null;
    }

    return item;
  }

  return getLineItem;
}
