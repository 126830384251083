import React from 'react';
import { StoreContextProvider, LocaleContext } from './src/contexts';
// import Layout from './src/layouts/layout';
// import { useCart } from './src/hooks/shop/useCart';

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
);

export const wrapPageElement = ({
  element,
  props: {
    pageContext: { site },
  },
}) => (
  <div>
    {element}
  </div>
);
