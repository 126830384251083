import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  buttonSecondary, buttonSmall, color, desktopVW, letterSpacing, linkSm, minTablet, mobileVW,
} from '../styles';

export const Button = ({
  type, children, className, url, size, handleClick = () => null,
} : {size?: string, type: string, children: any, className?: any, url?: string, handleClick?: any}) => {
  switch (type) {
    case 'transparent':
      return (
        // @ts-ignore
        <StyledTransparentButton className={className} onClick={() => handleClick()} to={url}>
          {children}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.64 13.64"><path d="M4.735 3.249a.459.459 0 00.461.46l4.088-.003L2.92 10.07a.459.459 0 00.65.65l6.363-6.365-.003 4.089a.461.461 0 00.922 0l.004-5.192a.459.459 0 00-.037-.179.432.432 0 00-.247-.246.459.459 0 00-.178-.037H5.2a.459.459 0 00-.461.46z" fill="#fff" /></svg>
        </StyledTransparentButton>
      );
    case 'link':
      // @ts-ignore
      if (size === 'is-sm') return <StyledSmallButton onClick={() => handleClick()} className={className} to={url}>{children}</StyledSmallButton>;
      // @ts-ignore
      return <StyledButton onClick={() => handleClick()} className={className} to={url}>{children}</StyledButton>;
    case 'button':
      // @ts-ignore
      if (size === 'is-sm') return <StyledSmallButton onClick={() => handleClick()} className={className} type="button">{children}</StyledSmallButton>;
      // @ts-ignore
      return <StyledButton onClick={() => handleClick()} className={className} type="button">{children}</StyledButton>;
    default:
      return null;
  }
};

const StyledTransparentButton = styled(Link)`
  border: 1px solid #C5C5C5;
  background: #f3f3f31c;
  cursor: pointer;
  color: ${color.white};
  text-decoration: none;
  border-radius: ${mobileVW(10)};
  padding: ${mobileVW(15)} ${mobileVW(20)};
  ${linkSm};
  letter-spacing: ${letterSpacing(30)};
  align-items: center;
  display: block;
  width: fit-content;
  margin: 0 auto;

  &:hover {
      background: ${color.white};
      color: ${color.black};
      border: 1px solid ${color.white};
  }

  &:hover {
      svg > path {
        fill: ${color.black};
      }
    }

  transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

  ${minTablet} {
    margin: 0;
    display: inline-flex;
    border-radius: ${desktopVW(10)};
    padding: ${desktopVW(15)} ${desktopVW(20)};
  }

  & + & {
    margin-top: ${mobileVW(10)};
    
    ${minTablet} {
      margin-top: 0;
      margin-left: ${desktopVW(40)};
    }
  }

  svg {
    width: ${mobileVW(14)};
    margin-left: ${mobileVW(5)};
    transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

    ${minTablet} {
      width: ${desktopVW(14)};
      margin-left: ${desktopVW(10)};
    }
  }
`;

const StyledButton = styled(Link).attrs((props) => ({
  as: props.type,
}))`
  &:hover {
    background: ${color.white};
    color: ${color.black};
  }

  transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

  ${buttonSecondary};
  cursor: pointer; 
`;

// Kheb ff geen idee wat hier fout gaat
const StyledSmallButton = styled(Link).attrs((props) => ({
  as: props.type,
}))`
  &:hover {
      background: ${color.black};
      color: ${color.white};
  }

  transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

  ${buttonSmall};
  cursor: pointer;
`;
