import { css } from 'styled-components';

export const minTablet = '@media (min-width: 768px)';
export const maxTablet = '@media (max-width: 767px)';

export const mobileVW = (px : number) => `${(px / 360) * 100}vw`;
export const desktopVW = (px : number) => `${(px / 1440) * 100}vw`;

export const ease = {
  expo: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
};

export const color = {
  black: '#080912',
  white: '#ffffff',
  greyDark: '#C5C5C5',
  greyLight: '#F3F3F3',
  green: '#41AE31',
  blue: '#0054FF',
  pink: '#FF0550',
  error: '#d65868',
};

export const font = {
  familyMedium: 'Gilroy, sans-serif',
  familySemiBold: 'Gilroy semiBold, sans-serif',
  familyBold: 'Gilroy bold, sans-serif',
  familyExtraBold: 'Gilroy extraBold, sans-serif',

  weightMedium: '500',
  weightSemiBold: '600',
  weightBold: '700',
  weightExtraBold: '800',

  sizeHeadingXl: desktopVW(100),
  lineHeightHeadingXl: desktopVW(110),

  sizeHeadingLg: desktopVW(90),
  lineHeightHeadingLg: desktopVW(91),

  sizeHeadingMd: desktopVW(76),
  lineHeightHeadingMd: desktopVW(75),

  sizeHeadingRg: desktopVW(60),
  lineHeightHeadingRg: desktopVW(60),

  sizeHeadingSm: desktopVW(36),
  lineHeightHeadingSm: desktopVW(65),

  sizeHeadingXs: desktopVW(33),
  lineHeightHeadingXs: desktopVW(39),

  sizeHeadingXxs: desktopVW(30),
  lineHeightHeadingXxs: desktopVW(44),

  sizeIntroRg: desktopVW(21),
  lineHeightIntroRg: desktopVW(30),

  sizeIntroSm: desktopVW(16),
  lineHeightIntroSm: desktopVW(32),

  sizeBody: desktopVW(18),
  lineHeightBody: desktopVW(25),

  sizeLink: desktopVW(14),
  lineHeightLink: desktopVW(24),

};

export const spacing = {
  s7: desktopVW(7),
  s10: desktopVW(10),
  s20: desktopVW(20),
  s30: desktopVW(30),
  s40: desktopVW(40),
  s50: desktopVW(50),
  s60: desktopVW(60),
  s70: desktopVW(70),
  s80: desktopVW(80),
  s90: desktopVW(90),
  s100: desktopVW(100),
  s180: desktopVW(180),
};

export const component = css`
  position: relative;
  margin: ${mobileVW(50)} 0;
  
  ${minTablet} {
    margin: ${desktopVW(120)} 0;
  }
`;

export const componentDark = css`
  position: relative;
  padding: ${desktopVW(120)} 0;
`;

export const container = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
  align-items: start;
  position: relative;
`;

export const radius = css`
  border-radius: ${mobileVW(20)};
  
  ${minTablet} {
    border-radius: ${spacing.s30};
  }
`;

export const heading = css`
  /* color: ${color.black}; */
  font-weight: ${font.weightExtraBold};
`;

export const headingXl = css`
  ${heading};
  font-size: ${mobileVW(50)};
  line-height: ${mobileVW(50)};

  ${minTablet} {
    font-size: ${font.sizeHeadingXl};
    line-height: ${font.lineHeightHeadingXl};
  }

`;

export const headingLg = css`
  ${heading};
  font-size: ${mobileVW(45)};
  line-height: ${mobileVW(45)};

  ${minTablet} {
    font-size: ${font.sizeHeadingLg};
    line-height: ${font.lineHeightHeadingLg};
  }
`;

export const headingMd = css`
  ${heading};
  font-size: ${mobileVW(35)};
  line-height: ${mobileVW(35)};

  ${minTablet} {
    font-size: ${font.sizeHeadingMd};
    line-height: ${font.lineHeightHeadingMd};
  }
`;

export const headingRg = css`
  ${heading};
  font-size: ${mobileVW(35)};
  line-height: ${mobileVW(35)};

  ${minTablet} {
    font-size: ${font.sizeHeadingRg};
    line-height: ${font.lineHeightHeadingRg};
  }
`;

export const headingSm = css`
  ${heading};
  font-weight: ${font.weightSemiBold};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(34)};

  ${minTablet} {
    font-size: ${font.sizeHeadingSm};
    line-height: ${font.lineHeightHeadingSm};
  }

`;

export const headingXs = css`
  ${heading};
  font-size: ${mobileVW(26)};
  line-height: ${mobileVW(32)};

  ${minTablet} {
    font-size: ${font.sizeHeadingXs};
    line-height: ${font.lineHeightHeadingXs};
  }
`;

export const headingXxs = css`
  ${heading};
  font-weight: ${font.weightMedium};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(31)};

  ${minTablet} {
    font-size: ${font.sizeHeadingXxs};
    line-height: ${font.lineHeightHeadingXxs};
  }
`;

export const introRg = css`
  color: ${color.black};
  font-weight: ${font.weightBold};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(23)};

  ${minTablet} {
    font-size: ${font.sizeIntroRg};
    line-height: ${font.lineHeightIntroRg};
  }
`;

export const introSm = css`
  color: ${color.black};
  font-weight: ${font.weightMedium};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(32)};

  ${minTablet} {
    font-size: ${font.sizeIntroSm};
    line-height: ${font.lineHeightIntroSm};
  }
`;

export const linkRg = css`
  font-weight: ${font.weightBold};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};

  ${minTablet} {
    font-size: ${font.sizeLink};
    line-height: ${font.lineHeightLink};
  }
`;

export const linkSm = css`
  font-weight: ${font.weightMedium};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};

  ${minTablet} {
    font-size: ${font.sizeLink};
    line-height: ${font.lineHeightLink};
  }
`;

export const body = css`
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(25)};

  ${minTablet} {
    font-size: ${font.sizeBody};
    line-height: ${font.lineHeightBody};
  }
`;

export const button = css`
  text-align: center;
  color: ${color.black};
  text-decoration: none;
  display: inline-block;
  border: 1px solid #C5C5C5;
  line-height: 1;
  border-radius: ${mobileVW(7)} ;

  ${minTablet} {
    border-radius: ${spacing.s7};
  }
`;

export const buttonSecondary = css`
  ${linkRg};
  ${button};
  color: ${color.white};
  padding: ${mobileVW(20)} ${mobileVW(40)};


  ${minTablet} {
    padding: ${desktopVW(20)} ${desktopVW(50)};
    border-radius: ${desktopVW(87)};
  }
`;

export const buttonSmall = css`
  ${linkSm};
  ${button};
  padding: ${mobileVW(10)};
  background: ${color.white};

  ${minTablet} {
    padding: ${desktopVW(7)} ${desktopVW(11)};
    border-radius: ${spacing.s7};
  }
`;

export const letterSpacing = (space : number) => `${(space / 1000) * 1}em`;
