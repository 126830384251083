/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group';
import { isBrowser } from '../utils';

const timeout = 400;
const getTransitionStyles:any = {
  entering: {
    position: 'absolute',
    width: '100vw',
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
    width: '100vw',
  },
  exiting: {
    width: '100vw',
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
};

interface componentProps {
  location: {
    pathname: string
  }
  children: any
}

const Transition = (props : componentProps) => {
  const { children, location } = props;

  if (!isBrowser) {
    return null;
  }

  const elOnExist = (el : any) => {
    const element = el;
    element.style.position = 'absolute';
    element.style.transform = `translateY(${-1 * window.scrollY}px)`;
  };

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        onExit={(dom : any) => elOnExist(dom)}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {(status) => (
          <div style={{ ...getTransitionStyles[status] }}>
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};

export default Transition;
