import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useCartItems } from '../../hooks/shop/useCartItems';
import { useRemoveItemFromCart } from '../../hooks/shop/useRemoveItemFromCart';
import { useRemoveItemsFromCart } from '../../hooks/shop/useRemoveItemsFromCart';
import { Typography } from '../../shared/Typography';
import {
  color, desktopVW, linkSm, maxTablet, minTablet, mobileVW,
} from '../../styles';

export const LineItems = ({
  name, price, amount, image, variantId, size, slug,
} : {name: string, slug: string, price: string, amount: number, image: any, variantId: string, size: string}) => {
  const removeItem = useRemoveItemFromCart();
  const removeItems = useRemoveItemsFromCart();
  const items = useCartItems();

  const rentalCartLineItems = items.filter((item : any) => [
    // TODO: get all rentalproducts to
    { title: 'Zender' },
    { title: 'Oplader koptelefoons' },
    { title: 'RGB koptelefoon' },
    { title: 'Polaroid-pakket' },
    { title: 'Rookmachine-pakket' },
    { title: 'Rookmachine pakket' },
    { title: 'Portable zender upgrade' },
    { title: 'Discolamp-pakket small' },
    { title: 'Discolamp-pakket medium : Laser + strobo + discolamp' },
    { title: 'Discolamp-pakket large: Rookmachine + Laser + strobo + discolamp' },
    { title: 'Polaroid pakket' }].some(({ title } : { title: string }) => title === item.title));

  const removeLineItem = async () => {
    if (rentalCartLineItems.some((item : any) => item.variant.id === variantId)) {
      try {
        await removeItems(rentalCartLineItems.map((item : any) => item.variant.id));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    } else {
      try {
        await removeItem(variantId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  // eslint-disable-next-line no-restricted-globals
  const isNumeric = (n : any) => !isNaN(parseFloat(n)) && isFinite(n);

  return (
    <StyledListItem>
      <StyledImageWrapper>
        <StyledImage src={image.src} />
      </StyledImageWrapper>
      <StyledTitle>
        <StyledTypography size="link-sm" variants="h4">
          {name}
          {' '}
          <br />
          <StyledEdit>
            <Link style={{ color: color.black, textDecoration: 'none' }} to={slug}>AANPASSEN</Link>
            {' '}
            |
            {' '}
            <button type="button" onClick={() => removeLineItem()}>VERWIJDEREN</button>
          </StyledEdit>
        </StyledTypography>
      </StyledTitle>
      <StyledSize>
        {/* {' '} */}
        {/* - */}
        {isNumeric(size) ? `${size} days` : size}
      </StyledSize>
      <td>{amount}</td>
      <td>
        <Typography size="link-sm" variants="h4">
          &euro;
          {(parseInt(price, 10) * amount).toFixed(2)}
        </Typography>
      </td>
    </StyledListItem>
  );
};

const StyledListItem = styled.tr`
  height: ${mobileVW(50)};
  position: relative;
  ${linkSm};

  border-bottom: 1px solid ${color.greyDark};
  
  ${minTablet} {
    height: ${desktopVW(60)};
  }
`;

const StyledSize = styled.td`
  /* font-size: ${mobileVW(10)}; */
`;

const StyledTitle = styled.td`
  padding: ${mobileVW(15)} ${mobileVW(10)} ${mobileVW(30)} ${mobileVW(15)};
  /* width: ${mobileVW(160)}; */
  /* white-space: nowrap; */

  ${maxTablet} {
    display: block;
    width: 100%;
  }

  ${minTablet} {
    padding: ${desktopVW(20)} ${desktopVW(50)} ${desktopVW(20)} ${desktopVW(15)};
    width: ${desktopVW(274)};
  }
`;

const StyledTypography = styled(Typography)`
  ${maxTablet} {
    /* font-size: ${mobileVW(10)}; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
  }
`;

const StyledImage = styled.img`
  background: ${color.greyLight};
  width: 100%;
  height: ${mobileVW(50)};
  object-fit: cover;
  aspect-ratio: 1;
  display: block;
  
  ${minTablet} {
    height: ${desktopVW(60)};
  }
`;

const StyledImageWrapper = styled.td`
  width: ${mobileVW(50)};
  
  ${minTablet} {
    width: ${desktopVW(60)};
  }
`;

const StyledEdit = styled.span`
  font-size: ${mobileVW(12)};
  
  ${minTablet} {
    font-size: ${desktopVW(12)};  
  }

  /* ${maxTablet} {
    white-space: nowrap;
    position: absolute;
  } */

  button {
    background-color: transparent;
    border: none;
    color: ${color.greyDark};
    font-size: ${mobileVW(12)};
    cursor: pointer;
    padding: 0;

    ${minTablet} {
      font-size: ${desktopVW(12)};  
    }
  }
`;
