import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCart } from '../../hooks/shop/useCart';
import { useCartItems } from '../../hooks/shop/useCartItems';
import { useCheckoutUrl } from '../../hooks/shop/useCheckoutUrl';
import { Button } from '../../shared/Button';
import { Typography } from '../../shared/Typography';
import {
  color, desktopVW, letterSpacing, linkSm, maxTablet, minTablet, mobileVW,
} from '../../styles';
import { isDevice } from '../../utils';
import { LineItems } from './LineItems';

export const Cart = ({ open, setOpen }: any) => {
  const items = useCartItems();
  const cart:any = useCart();
  const cartUrl = useCheckoutUrl();

  // Fix SSR issue where wrong DOM element gets displayed in production
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  if (!mounted) return null;

  const mainRentalProduct:any = items.filter((lineItem : {title: string}) => lineItem.title === 'RGB koptelefoon')[0];

  const rentalCartLineItems = items.filter((item : any) => [
    // TODO: get all rentalproducts to
    { title: 'Zender' },
    { title: 'Oplader koptelefoons' },
    { title: 'RGB koptelefoon' },
    { title: 'Polaroid-pakket' },
    { title: 'Rookmachine-pakket' },
    { title: 'Rookmachine pakket' },
    { title: 'Portable zender upgrade' },
    { title: 'Discolamp-pakket small' },
    { title: 'Discolamp-pakket medium : Laser + strobo + discolamp' },
    { title: 'Discolamp-pakket large: Rookmachine + Laser + strobo + discolamp' },
    { title: 'Polaroid pakket' }].some(({ title } : { title: string }) => title === item.title));

  const staticCartLineItems = items && items.filter((item : {title: string}) => !rentalCartLineItems.some(({ title } : {title: string}) => title === item.title));

  const convertToSlug = (text: string) => text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

  return (
    <StyledCart open={open}>
      <StyledHeading>
        <StyledTypography size="is-sm" variants="h3">
          Jouw winkelmandje
        </StyledTypography>
        <StyledCross onClick={() => setOpen(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.305 40.305"><g fill="none" stroke="#000"><path d="M39.952.353L.354 39.951M.354.353l39.598 39.598" /></g></StyledCross>
      </StyledHeading>
      <ScrollContainer>
        {mainRentalProduct && (
          <StyledTable>
            <thead>
              <StyledTableHeading>
                <StyledTh colSpan={2}>
                  Rental,
                  {' '}
                  <span>{mainRentalProduct.customAttributes[0].value}</span>
                  {' '}
                  t/m
                  {' '}
                  <span>{mainRentalProduct.customAttributes[1].value}</span>
                </StyledTh>
                <StyledTh>Size</StyledTh>
                <StyledTh className="spacing__mobile">{isDevice() ? 'Hvl' : 'Hoeveelheid' }</StyledTh>
                <StyledTh>Prijs</StyledTh>
              </StyledTableHeading>
              <SpacingTr />
            </thead>
            <tbody>
              {rentalCartLineItems.length > 0 ? rentalCartLineItems.map(({
                title, variant, quantity, id,
              }: any) => <LineItems slug="/shop/zelfsamenstellen" size={variant.title} variantId={variant.id} key={id} name={title} price={variant.price} image={variant.image} amount={quantity} />)
                : <p>Je winkelwagentje is leeg.</p>}
            </tbody>
          </StyledTable>
        )}
        <StyledTable>
          <thead>
            <StyledTableHeading>
              <StyledTh colSpan={2}>Producten</StyledTh>
              <StyledTh>Size</StyledTh>
              <StyledTh className="spacing__mobile">{isDevice() ? 'Hvl' : 'Hoeveelheid' }</StyledTh>
              <StyledTh>Prijs</StyledTh>
            </StyledTableHeading>
            <SpacingTr />
          </thead>
          <tbody>
            {/* {console.log(staticCartLineItems)} */}
            {staticCartLineItems.length > 0 ? staticCartLineItems.map(({
              title, variant, quantity, id,
            }: any) => <LineItems slug={`/product/${convertToSlug(title)}`} size={variant.title} variantId={variant.id} key={id} name={title} price={variant.price} image={variant.image} amount={quantity} />)
              : <p>Je winkelwagentje is leeg.</p>}
          </tbody>
        </StyledTable>
      </ScrollContainer>
      <StyledCheckOut>
        <StyledColumn>
          <StyledInfo>
            <div>Totaal:</div>
            <div>
              &euro;
              {' '}
              {parseFloat(cart.totalPrice).toFixed(2).toLocaleString()}
            </div>
          </StyledInfo>
          <StyledInfo><span>(Inclusief 21% BTW)</span></StyledInfo>
        </StyledColumn>
        <StyledColumn>
          <a href={cartUrl || undefined}>
            <StyledButton type="button" size="is-sm">Naar afrekenen</StyledButton>
          </a>
        </StyledColumn>
      </StyledCheckOut>
    </StyledCart>
  );
};

const StyledCart = styled.div`
  background: ${color.greyLight};
  position: fixed;
  top: 0; right: 0;
  padding: ${mobileVW(15)};
  z-index: 99;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  box-shadow: 0;
  width: 100%;

  display: ${(props : {open : boolean}) => (props.open ? 'block' : 'none')};

  ${minTablet} {
    width: unset;
    padding: ${desktopVW(30)};
    box-shadow: ${desktopVW(-14)} ${desktopVW(2)} ${desktopVW(20)} #00000016;
  }
`;

const StyledHeading = styled.div`
  padding-bottom: ${mobileVW(30)};
  margin-bottom: ${mobileVW(0)};
  border-bottom: 2px solid ${color.black};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${minTablet} {
    justify-content: inherit;
    padding-bottom: ${desktopVW(30)};
    margin-bottom: ${desktopVW(50)};
  }
`;

const StyledTypography = styled(Typography)`
  font-size: ${mobileVW(36)};

  ${minTablet} {
    font-size: ${desktopVW(56)};
    margin-right: ${desktopVW(100)};
  }
`;

const StyledCross = styled.svg`
  width: ${mobileVW(40)}; 
  cursor: pointer;
  
  ${minTablet} {
    width: ${desktopVW(40)}; 
  }
`;

const StyledTable = styled.table`
  width: 100%;

  & + & {
    margin-top: ${mobileVW(30)};
    
    ${minTablet} {
      margin-top: ${desktopVW(50)};
    }
  }

  td {
    vertical-align: middle;
  }
`;

const ScrollContainer = styled.div`
  height: calc(100vh - ${mobileVW(202)});
  overflow: scroll;
  
  ${minTablet} {
    height: calc(100vh - ${desktopVW(300)});
  }
`;

const StyledTableHeading = styled.tr`
  border-bottom: ${mobileVW(1)} solid ${color.greyDark};
  
  ${minTablet} {
    border-bottom: ${desktopVW(1)} solid ${color.greyDark};
  }
  
  th:first-child {
    font-size: ${mobileVW(16)};
    padding-right: ${mobileVW(10)};
    /* width: ${mobileVW(330)}; */
    color: ${color.black};
    
    ${minTablet} {
      font-size: ${desktopVW(19)};
      padding-right: ${desktopVW(120)};
      width: ${desktopVW(330)};  
    }

    span {
      color: ${color.blue};
    }
  }
`;

const StyledTh = styled.th`
  text-align: left;
  ${linkSm};
  color: #5B5E69;
  font-weight: 400;
  padding-bottom: ${mobileVW(15)};
  vertical-align: bottom;
  
  ${maxTablet} {
    padding-top: ${mobileVW(30)};

    &.spacing__mobile {
      padding: 0 ${mobileVW(10)} ${mobileVW(15)};
    }
  }

  ${minTablet} {
    padding-bottom: ${desktopVW(15)};
  }
`;

const SpacingTr = styled.tr`
  height: ${mobileVW(30)};
  
  ${minTablet} {
    height: ${desktopVW(30)};
  }
`;

const StyledCheckOut = styled.div`
  position: absolute;
  bottom: 0; left: 0; right: 0;
  background: ${color.greyLight};
  padding: ${mobileVW(15)} 0;
  margin: 0 ${mobileVW(15)};
  border-top: 2px solid ${color.black};
  display: flex;
  justify-content:  space-between;
  
  ${minTablet} {
    padding: ${desktopVW(30)} 0;
    margin: 0 ${desktopVW(30)};
  }
`;

const StyledInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${mobileVW(120)};
  
  ${minTablet} {
    width: ${desktopVW(300)};
  }

  span {
    color: #5B5E69;
    font-size: ${mobileVW(12)};
    
    ${minTablet} {
      font-size: ${desktopVW(13)};
    }
  }
`;

const StyledColumn = styled.div``;

const StyledButton = styled(Button)`
  color: ${color.white};
  background: ${color.black};
  border: none;
  /* width: ${mobileVW(330)}; */
  padding: ${mobileVW(15)};
  font-size: ${mobileVW(17)};
  letter-spacing: ${letterSpacing(30)};
  
  ${minTablet} {
    width: ${desktopVW(330)};
    padding: ${desktopVW(19)};
    font-size: ${desktopVW(19)};
}
`;
