const CART = 'shopify_local_store__cart';
const CHECKOUT_ID = 'shopify_local_store__checkout_id';
const USER = 'shopify_local_user';
const COOKIE = 'cookie_notification';
const NEWSLETTER = 'newsletter_notification';
const WISHLIST = 'wishlist';

export const LocalStorageKeys = {
  CART,
  CHECKOUT_ID,
  USER,
  COOKIE,
  NEWSLETTER,
  WISHLIST,
};
