import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

import NavItem from './NavItem';
import {
  color, desktopVW, minTablet, mobileVW,
} from '../../styles';
import { Button } from '../../shared/Button';
import { isDevice } from '../../utils';
import { useCartCount } from '../../hooks/shop/useCartCount';
import { Cart } from '../../components/cart/Cart';

interface DataInterface {
  wpMenu: {
    menuItems: {
      nodes: NavMainInterface[]
    }
  }
}

interface NavMainInterface {
  id: string;
  parentId: null;
  label: string;
  path: string;
  seperator?: boolean;
  childItems: {
    nodes: NavChildInterface[]
  }
}

interface NavChildInterface {
  id: string;
  label: string;
  path: string;
}

const Nav = () => {
  const data: DataInterface = useStaticQuery(graphql`
    query getMainMenu {
      wpMenu(slug: {eq: "main-menu"}) {
        menuItems {
          nodes {
            id
            parentId
            label
            path
            childItems {
              nodes {
                id
                path
                label
              }
            }
          }
        }
      }
    }
  `);

  const menu: NavMainInterface[] | null = data.wpMenu.menuItems.nodes && data.wpMenu.menuItems.nodes.filter((menuItem) => menuItem.parentId === null);
  const cartCount = useCartCount();
  const [open, setOpen] = useState(false);

  const MobileMenu = () => {
    const [active, setActive] = useState(false);

    return (
      <>
        <Button handleClick={() => setActive(!active)} size="is-sm" type="button">
          Menu
        </Button>
        <StyledMenu open={active}>
          {[...menu, { label: 'Shop', path: '/shop/' }].map((menuItem: any) => <NavItem navItem={menuItem} key={menuItem.id} />)}
        </StyledMenu>
      </>
    );
  };

  return (
    <>
      <Cart open={open} setOpen={setOpen} />
      <Spacing />
      <StyledNav>
        <StyledColumn>
          <StyledLogoContainer to="/">
            <StyledLogo fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.23 115.44">
              <path d="M156.21 2.87h20.18v107.61h-20.18zM217.57 34.81c0-17.64-14.3-31.95-31.95-31.95h-1.42v20.18h1.42c6.5 0 11.77 5.27 11.77 11.77s-5.27 11.77-11.77 11.77h-1.42v20.18h1.42c17.65 0 31.95-14.3 31.95-31.95M188.16 110.47c17.64 0 31.95-14.3 31.95-31.95 0-6.32-1.84-12.21-5.01-17.17a39.705 39.705 0 01-16.88 11.08 11.69 11.69 0 011.71 6.08c0 6.5-5.27 11.77-11.77 11.77v.01h-3.96v20.18h3.96zM4.53 2.93H24.7v107.58H4.53z" />
              <g><path d="M65.87 34.87c0-17.64-14.3-31.94-31.94-31.94h-1.42V23.1h1.42c6.5 0 11.77 5.27 11.77 11.77s-5.27 11.77-11.77 11.77h-1.42v20.17h1.42c17.64 0 31.94-14.3 31.94-31.94M33.93 74.54h-.21l13.3 35.97h21.39L53.28 69.49a39.48 39.48 0 01-19.35 5.05M78.65 33.12v47.19c0 13.48 8.36 25.01 20.17 29.7V3.42C87 8.11 78.65 19.64 78.65 33.12M142.52 56.05h-35.89v20.17h15.72v4.1c0 6.5-5.27 11.77-11.77 11.77h-3.95v19.93c1.3.16 2.61.24 3.95.24 17.64 0 31.94-14.3 31.94-31.94V56.05zM122.35 33.13v4.73h20.17v-4.73c0-17.64-14.3-31.94-31.94-31.94-1.34 0-2.66.08-3.95.24v19.93h3.95c6.5 0 11.77 5.27 11.77 11.77" /></g>
            </StyledLogo>
          </StyledLogoContainer>
          <StyledUl>
            {[...menu, { seperator: true, label: 'Zelf samenstellen', path: '/shop/zelfsamenstellen' }, { label: 'Merchandise', path: '/merchandise/' }].map((menuItem) => <NavItem navItem={menuItem} key={menuItem.label} />)}
          </StyledUl>
        </StyledColumn>
        <StyledRightSide>
          {isDevice() ? <MobileMenu /> : (
            <Button url="/shop" size="is-sm" type="link">
              Verhuren opties
            </Button>
          )}
          <div
            onClickCapture={() => setOpen(true)}
            style={{
              cursor: 'pointer', position: 'relative', textDecoration: 'none', display: 'inline-grid',
            }}
          >
            <StyledCartCount><span>{cartCount}</span></StyledCartCount>
            <StyledIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.529 18.214">
              <path d="M14.784 13.104a2.555 2.555 0 102.555 2.555 2.555 2.555 0 00-2.555-2.555zm0 3.538a.985.985 0 11.985-.983.985.985 0 01-.985.983zM18.733 2.964H5.627l-.319-1.328A2.125 2.125 0 003.235 0H.785a.785.785 0 000 1.57h2.468a.559.559 0 01.545.431l1.945 8.189a3.02 3.02 0 002.946 2.324h6.416a3.015 3.015 0 002.955-2.344l1.449-6.246a.785.785 0 00-.776-.962zm-2.208 6.854a1.451 1.451 0 01-1.42 1.122H8.689a1.454 1.454 0 01-1.418-1.122L6 4.534h11.752zM8.413 13.104a2.555 2.555 0 102.562 2.555 2.555 2.555 0 00-2.562-2.555zm0 3.538a.985.985 0 11.985-.983.985.985 0 01-.985.983z" />
            </StyledIcon>
          </div>
        </StyledRightSide>
      </StyledNav>
    </>
  );
};

const Spacing = styled.div`
  padding-top: ${mobileVW(83)};
  
  ${minTablet} {
    padding-top: ${desktopVW(94)};
  }
`;

const StyledNav = styled.header`
  top: 0;
  left: ${mobileVW(15)};
  right: ${mobileVW(24)};
  height: ${mobileVW(83)};
  z-index: 9;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  position:  absolute;

  ${minTablet} {
    height: ${desktopVW(94)};
    left: ${desktopVW(60)};
    right: ${desktopVW(67)};
  }
`;

const StyledColumn = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.svg`
  position: relative;
  width: ${mobileVW(20)};

  ${minTablet} {
    width: ${desktopVW(19)};
  }
`;

const StyledLogo = styled.svg`
  width: ${mobileVW(25)};

  ${minTablet} {
    width: ${desktopVW(25)};
  }
`;

const StyledUl = styled.ul`
  margin: 0 auto;
  display: none;
  align-items: center;

  ${minTablet} {
    display: flex;
  }
`;

const StyledRightSide = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-self: flex-end;
  grid-gap: 0 ${mobileVW(28)};
  
  ${minTablet} {
    grid-gap: 0 ${desktopVW(32)};
  }
`;

const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: -1;
  padding: ${mobileVW(100)} ${mobileVW(15)};

  display: ${({ open } : { open : boolean}) => (open ? 'block' : 'none')};
`;

const StyledCartCount = styled.div`
  font-size: ${mobileVW(10)};
  background: ${color.white};
  border-radius: 50%;
  z-index: 1;
  width: ${mobileVW(18)};
  height: ${mobileVW(18)};
  color: ${color.black};
  position: absolute;
  right: ${mobileVW(-10)};
  bottom: ${mobileVW(-6)};
  font-weight: bold;
  box-shadow: 0px 0px 6px 5px #a0a0a031;
  
  ${minTablet} {
    font-size: ${desktopVW(10)};
    width: ${desktopVW(18)};
    height: ${desktopVW(18)};
    right: ${desktopVW(-10)};
    bottom: ${desktopVW(-6)};
  }

  span {
    position: absolute;
    top: 52%; left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledLogoContainer = styled(Link)`
  background: ${color.black};
  width: ${mobileVW(50)};
  height: ${mobileVW(50)};
  display: flex; align-items: center; justify-content: center;

  margin-right: 0;

  border-radius: 50%;

  ${minTablet} {
    width: ${desktopVW(44)};
    height: ${desktopVW(44)};
    margin-right: ${desktopVW(30)};
  }
`;

export default Nav;
