import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  color, desktopVW, headingSm, linkSm, minTablet, mobileVW,
} from '../../styles';

interface NavItemInterface {
  path: string;
  label: string;
  seperator?: boolean;
}

const NavItem = ({ navItem }: { navItem: NavItemInterface }) => {
  const getSelectedPath = typeof window !== 'undefined' && window.location.pathname === navItem.path;

  return (
    <>
      {navItem.seperator && (
      <StyledLi>
        <StyledLine />
      </StyledLi>
      )}
      <StyledLi className={getSelectedPath ? 'active' : undefined}>
        <StyledLink className="nav__first" to={navItem.path}>{navItem.label}</StyledLink>
        <StyledLink className="nav__second" to={navItem.path}>{navItem.label}</StyledLink>
      </StyledLi>
    </>
  );
};

const StyledLi = styled.li`
  display: block;
  position: relative;
  overflow: hidden;

  &:hover {
    .nav__first, .nav__second {
      transform: translateY(-100%);
    }
  }

  & + & {
    margin-top: ${mobileVW(10)};
  }

  ${minTablet} {
    display: inline-block;

    & + & {
      margin-left: ${desktopVW(30)};
      margin-top: 0;
    }
  }

`;

const StyledLink = styled(Link)`
  ${headingSm};
  color: ${color.black};
  text-decoration: none;
  display: block;

  transition: transform 0.68s cubic-bezier(0.16, 1, 0.3, 1);
  
  &:last-child {
    position: absolute;
    top: 100%;
    left: 0;
  }

  ${minTablet} {
    ${linkSm};
  }
`;

const StyledLine = styled.div`
  background: #C5C5C5;
  width: 1px;
  height: ${desktopVW(14)};
`;

export default NavItem;
