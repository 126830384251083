import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import {
  color, desktopVW, minTablet, mobileVW,
} from '../styles';

export const Socials = ({ className } : { className?: any}) => {
  const data = useStaticQuery(graphql`
    query getSocials {
      allWp {
        edges {
          node {
            acfOptionsContactDetails {
              acfGlobal {
                social {
                  socials {
                    url
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { socials } = data.allWp.edges[0].node.acfOptionsContactDetails.acfGlobal.social;

  return (
    <StyledSocials className={className}>
      {/* @ts-ignore */}
      {socials.map((item) => {
        switch (item.type) {
          case 'linkedin':
            return <div key={item.type}>linkedin</div>;
          case 'twitter':
            return (
              <StyledLink key={item.type} href={item.url} target="_blank" rel="noreferrer">
                <StyledIcon size={[20.26, 16.4]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.259 16.4"><path d="M17.478 2.77A3.95 3.95 0 0019.464.4a5.417 5.417 0 01-2.483 1.05 3.993 3.993 0 00-4.969-.98 3.93 3.93 0 00-1.9 4.661h-.184A11.671 11.671 0 011.589.8a3.7 3.7 0 001.192 5.519s-1.589 0-1.986-.394c0 0-.4 2.365 3.178 3.942a1.93 1.93 0 01-1.589 0c0 1.523 1.6 2.76 3.575 2.76C5.958 13.715 3.292 14.6 0 14.6c5.164 3.548 17.478 2.76 18.273-10.644l1.986-1.971z" fill="#fff" /></StyledIcon>
              </StyledLink>
            );
          case 'instagram':
            return <div key={item.type}>instagram</div>;
          case 'facebook':
            return (
              <StyledLink key={item.type} href={item.url} target="_blank" rel="noreferrer">
                <StyledIcon size={[18, 18]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M18 8.5a8.38 8.38 0 01-.9 3.8A8.5 8.5 0 019.5 17a8.38 8.38 0 01-3.8-.9L0 18l1.9-5.7A8.38 8.38 0 011 8.5 8.5 8.5 0 015.7.9 8.38 8.38 0 019.5 0h.5a8.48 8.48 0 018 8v.5z" fill="#fff" fillRule="evenodd" /></StyledIcon>
              </StyledLink>
            );
          case 'youtube':
            return (
              <StyledLink key={item.type} href={item.url} target="_blank" rel="noreferrer">
                <StyledIcon size={[21, 15]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15"><path d="M10.5 0q3.01 0 6.244.224l1.084.081a3 3 0 012.706 2.416A24.908 24.908 0 0121 7.5a24.812 24.812 0 01-.357 4.182l-.109.6a3 3 0 01-2.706 2.418q-3.816.3-7.328.3-3.01 0-6.244-.224L3.172 14.7a3 3 0 01-2.706-2.416 24.708 24.708 0 01-.109-8.961l.109-.6A3 3 0 013.172.3Q6.988 0 10.5 0zm-1 4.369a1 1 0 00-1 1v4.263a1 1 0 001.555.832l3.2-2.131a1 1 0 000-1.664l-3.2-2.131a1 1 0 00-.555-.169z" fill="#fff" /></StyledIcon>
              </StyledLink>
            );
          default:
            return null;
        }
      })}
    </StyledSocials>
  );
};

const StyledSocials = styled.div`
  display: flex;
`;

const StyledIcon = styled.svg`
   ${({ size } : {size : any}) => (
    `width: ${mobileVW(size[0])}; height: ${mobileVW(size[1])};`
  )}
  
  ${minTablet} {
    ${({ size } : {size : any}) => (
    `width: ${desktopVW(size[0])}; height: ${desktopVW(size[1])};`
  )}
  }
`;

const StyledLink = styled.a`
  & + & {
    margin-left: ${mobileVW(15)};
    
    ${minTablet} {
      margin-left: ${desktopVW(15)};
    }
  }
  
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.pink};
  width: ${mobileVW(33)};
  height: ${mobileVW(33)};
  
  ${minTablet} {
    width: ${desktopVW(33)};
    height: ${desktopVW(33)};
  }
`;
