import { createGlobalStyle } from 'styled-components';
import React from 'react';

import Nav from '../nav/Nav';
import Footer from '../Footer';

import '../../styles/reset.css';
import '../../fonts/font.css';

import { body } from '../../styles';

import Transition from '../../components/Transition';
import { isBrowser } from '../../utils';

type Props = {
  children: React.ReactNode;
  refParent: any,
  location: any
};

const addScript = (src : string) => {
  if (isBrowser) {
    // Add timeout to prevent other scripts from loading and increase performance
    setTimeout(() => {
      const s = document.createElement('script');
      s.setAttribute('src', src);
      document.body.appendChild(s);
    }, 100);
  }
};

if (isBrowser) {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}
const Layout = ({
  children, refParent, location,
} : Props) => (
  <div ref={refParent} className="app">
    <GlobalStyle />
    <Transition location={location}>
      <Nav />
      <main>
        { children }
      </main>
      <Footer />
    </Transition>
  </div>
);

addScript('//code.tidio.co/b10lbslsxufavaz2ah0tbgzofy688eff.js');

const bg = [
  'background: white;',
  'color: black',
  'padding: 10px 20px 10px 20px',
  'line-height: 33px',
  'font-weight: 700',
  'font-family: sans-serif',
  'font-size: 12px',
].join(';');

// eslint-disable-next-line no-console
console.log('%c DEVELOPMENT BY GEWEST13 🦁', bg);

const GlobalStyle = createGlobalStyle`
  body, button {
    font-family: 'Gilroy', sans-serif;
    ${body}
  }

  .component:first-of-type {
    margin-top: 0;
  }
`;

export default Layout;
