import React, { useEffect, useState } from 'react';
// @ts-ignore
import ShopifyBuy from 'shopify-buy';
import { LocalStorage, LocalStorageKeys } from './utils';

interface InitialLayout {
  cartIsOpen: boolean
  menuIsOpen: boolean
  menuFromMobile: boolean
  sizeguideIsOpen: boolean
  sizeguideData: any
  shopTheLookIsOpen: boolean
  notifyMeIsOpen: boolean
  notifyMeProductVariant: string
  notifyMeProductId: string
  productSliderIsOpen: boolean
  productSliderImageId: string
  filterIsOpen: boolean
  activeFilter: any
  productAmount: number
  sortBy: string
  selectedSize: string
  notificationBanner: {
    active: boolean
    warning?: boolean
    message: string
  }
}
const InitialLayoutState = {
  cartIsOpen: false,
  menuIsOpen: false,
  menuFromMobile: false,
  sizeguideIsOpen: false,
  sizeguideData: null,
  shopTheLookIsOpen: false,
  notifyMeIsOpen: false,
  notifyMeProductVariant: '',
  notifyMeProductId: '',
  productSliderIsOpen: false,
  productSliderImageId: '',
  filterIsOpen: false,
  activeFilter: [],
  productAmount: 0,
  sortBy: '',
  selectedSize: null,
  notificationBanner: {
    active: false,
    warning: false,
    message: '',
  },
};

interface ContextShape {
  client: ShopifyBuy.Client | null
  cart: ShopifyBuy.Cart | null
  setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Cart | null>>
  layout: InitialLayout
  setLayout: React.Dispatch<React.SetStateAction<any | null>>
  wishlist: string[]
  setWishlist: React.Dispatch<React.SetStateAction<string[]>>
  shopifyLang: any | string,
  setShopifyLang: React.Dispatch<React.SetStateAction<string>>
}
export const StoreContext = React.createContext<ContextShape>({
  client: null,
  cart: null,
  setCart: () => {
    throw Error('You forgot to wrap this in a Provider object');
  },
  // @ts-ignore
  layout: InitialLayoutState,
  setLayout: () => {
    throw Error('You forgot to wrap this in a Provider object');
  },
  wishlist: [],
  setWishlist: () => {
    throw Error('You forgot to wrap this in a Provider object');
  },
});

// export function KlaviyoKey() {
//   return process.env.KLAVIYO_API;
// }

export function StoreContextProvider({ children } : {children: any}) {
  const initialCart = LocalStorage.getInitialCart();
  const initialWishlist = LocalStorage.getInitialWishlist();
  const [cart, setCart] = useState<ShopifyBuy.Cart | null>(initialCart);
  const [wishlist, setWishlist] = useState<string[]>(initialWishlist);
  const [layout, setLayout] = useState<any | null>(InitialLayoutState);
  // const [shopifyLang, setShopifyLang] = useState<any | string>(null);

  const client = ShopifyBuy.buildClient({
    storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN || '',
    domain: process.env.SHOPIFY_STORE_URL || '',
  });

  useEffect(() => {
    async function getNewCart() {
      const newCart = await client.checkout.create();
      setCart(newCart);
    }

    async function refreshExistingCart(cartId: string) {
      try {
        const refreshedCart = await client.checkout.fetch(cartId);
        // @ts-ignore
        const cartHasBeenPurchased = refreshedCart && refreshedCart.completedAt != null;
        if (cartHasBeenPurchased || cartHasBeenPurchased === null) {
          getNewCart();
        } else {
          setCart(refreshedCart);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (cart == null) {
      getNewCart();
    } else {
      refreshExistingCart(String(cart.id));
    }
  }, []);

  useEffect(() => {
    LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(cart));
  }, [cart]);

  return (
    <StoreContext.Provider
      // @ts-ignore
      value={{
        client,
        cart,
        setCart,
        layout,
        setLayout,
        wishlist,
        setWishlist,
        // setShopifyLang,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}
export const LocaleContext = React.createContext({ locale: '' });
