import { useContext } from 'react';
import { StoreContext } from '../../contexts';

export function useCheckoutUrl(): string | null {
  const { cart } = useContext(StoreContext);
  if (cart == null) {
    return null;
  }

  // @ts-ignore
  return cart.webUrl;
}
